import { buyBlue, sellRed } from '@features/dashboard/widgets/trading-v2/constants';
import { Box, Button, ButtonProps, Stack, styled, Typography } from '@mui/material';
import { CreateLadderRequest, LadderSide } from '@protos/ladders';
import { Product, ProductTenor } from '@protos/product';
import { useUserContext } from '@shared/contexts/UserContextProvider';
import { useUserProductsAndTenorsContext } from '@shared/contexts/UserProductsAndTenorsProvider';
import { addSecondsToNow } from '@utils/date';
import { useCallback, useEffect, useMemo, useState } from 'react';
import NumberInput from './components/NumberInput';
import { StyledAutocomplete } from './components/StyledAutocomplete';
import { useLaddersApi } from './hooks/useLaddersApi';

type TradingButtonProps = { isBuy?: boolean } & ButtonProps;
const StyledButton = styled(Button, { shouldForwardProp: propName => propName !== 'isBuy' })<TradingButtonProps>(({ theme, isBuy }) => ({
  borderRadius: 5,
  width: '100%',
  backgroundColor: isBuy ? buyBlue : sellRed,
  display: 'flex',
  flexDirection: 'column',
  padding: 5,
  height: 45,
  marginBottom: 5,
  transition: 'background-color 0.3s ease, transform 0.3s ease',

  '&:hover': {
    backgroundColor: isBuy ? '#6096eb !important' : '#c96565 !important',
    transform: 'translateY(5%)',
    cursor: 'pointer',
  },
}));

export const SimpleOCLTrading = () => {
  const user = useUserContext();
  const { createLadder } = useLaddersApi();
  const { userProducts, tenors } = useUserProductsAndTenorsContext();

  const allMonthlyTenors = useMemo(() => tenors.filter(tenor => tenor.frequency === 'monthly'), [tenors]).slice(1);

  const [symbol, setSymbol] = useState<Product | null>(null);
  const [tenor, setTenor] = useState<ProductTenor | null>(null);
  const [frontTenor, setFrontTenor] = useState<ProductTenor | null>(null);
  const [backTenor, setBackTenor] = useState<ProductTenor | null>(null);
  const [price, setPrice] = useState<number | ''>('');
  const [lots, setLots] = useState<number | ''>('');
  const [duration, setDuration] = useState<number | ''>('');
  const [selectedAccount, setSelectedAccount] = useState<string | ''>(user?.abn_trad_accounts?.[0] || '');

  const allMonthlyBackTenors = useMemo(
    () => allMonthlyTenors.slice(allMonthlyTenors.findIndex(tenor => tenor.code === frontTenor?.code) + 1),
    [allMonthlyTenors, frontTenor]
  );

  const isFormValid = useMemo(
    () =>
      symbol &&
      (price || price === 0) &&
      lots &&
      duration &&
      selectedAccount &&
      (symbol.calendar_type === 'spread' ? frontTenor && backTenor : tenor),
    [symbol, price, lots, duration, selectedAccount, frontTenor, backTenor, tenor]
  );

  const handleSubmit = useCallback(
    (type: 'Bid' | 'Ask') => {
      if (!symbol || !price || !lots || !duration) return;

      const isSpreadSymbol = symbol.calendar_type === 'spread';

      if (isSpreadSymbol && (!frontTenor || !backTenor)) return;
      if (!isSpreadSymbol && !tenor) return;

      const ladderCreateRequestPayload: CreateLadderRequest = {
        symbol: isSpreadSymbol ? `${symbol.symbol}${frontTenor!.code}-${symbol.symbol}${backTenor!.code}` : `${symbol.symbol}${tenor!.code}`,
        expiry_timestamp: addSecondsToNow(+duration),
        orders: [
          {
            price: +price,
            side: type === 'Bid' ? LadderSide.BUY : LadderSide.SELL,
            amount: +lots,
            hidden: false,
          },
        ],
        account_id: selectedAccount,
      };

      createLadder(ladderCreateRequestPayload);
    },
    [symbol, tenor, price, lots, duration, frontTenor, backTenor, createLadder, selectedAccount]
  );

  useEffect(() => {
    if (user?.abn_trad_accounts?.length && !selectedAccount) {
      setSelectedAccount(user.abn_trad_accounts[0]);
    }
  }, [user]);

  return (
    <Box sx={{ width: 400, p: 2 }}>
      <Box sx={{ display: 'flex', flex: 1, gap: 1 }}>
        <Typography variant="h6" gutterBottom marginBottom={4} minWidth="70%">
          Manual Order Entry Form
        </Typography>
        <StyledAutocomplete
          options={user?.abn_trad_accounts || []}
          getOptionLabel={(option: unknown) => (option as string).toUpperCase()}
          value={selectedAccount}
          onChange={(_, newValue) => newValue && setSelectedAccount(newValue as string)}
          label="Account"
          isOptionEqualToValue={(option, value) => (option as string).toUpperCase() === (value as string).toUpperCase()}
        />
      </Box>

      <Stack spacing={2}>
        {/* Symbol Autocomplete */}
        <StyledAutocomplete
          options={userProducts}
          getOptionLabel={(option: unknown) => (option as Product).symbol.toUpperCase()}
          value={symbol}
          onChange={(_, newValue) => newValue && setSymbol(newValue as Product)}
          label="Symbol"
          isOptionEqualToValue={(option, value) => (option as Product).symbol === (value as Product).symbol}
        />

        {/* Tenor Autocomplete */}
        {symbol?.calendar_type === 'spread' ? (
          <Box sx={{ display: 'flex', flex: 1, gap: 1 }}>
            <StyledAutocomplete
              options={allMonthlyTenors}
              getOptionLabel={(option: unknown) => (option as ProductTenor).display.toUpperCase()}
              value={frontTenor}
              onChange={(_, newValue) => newValue && setFrontTenor(newValue as ProductTenor)}
              label="Tenor One"
              isOptionEqualToValue={(option, value) => (option as ProductTenor).display === (value as ProductTenor).display}
            />
            <StyledAutocomplete
              options={allMonthlyBackTenors}
              getOptionLabel={(option: unknown) => (option as ProductTenor).display.toUpperCase()}
              value={backTenor}
              onChange={(_, newValue) => newValue && setBackTenor(newValue as ProductTenor)}
              label="Tenor Two"
              isOptionEqualToValue={(option, value) => (option as ProductTenor).display === (value as ProductTenor).display}
            />
          </Box>
        ) : (
          <StyledAutocomplete
            options={allMonthlyTenors}
            getOptionLabel={(option: unknown) => (option as ProductTenor).display.toUpperCase()}
            value={tenor}
            onChange={(_, newValue) => newValue && setTenor(newValue as ProductTenor)}
            label="Tenor"
            isOptionEqualToValue={(option, value) => (option as ProductTenor).display === (value as ProductTenor).display}
          />
        )}

        {/* Price Input */}
        <NumberInput
          value={price.toString()}
          onChange={value => setPrice(Number(value))}
          label="Price"
          min={-99999}
          width="100%"
          sx={{
            '& .MuiInputBase-input': {
              fontSize: '11px',
              '--Input-minHeight': '0',
            },
            '& .MuiInputLabel-root': {
              fontSize: '11px',
              '&.Mui-focused, &.MuiFormLabel-filled': {
                fontSize: '13px',
              },
            },
            '& .MuiInputBase-root': {
              borderRadius: '0px',
              height: 35,
            },
          }}
        />

        {/* Lots Input (min value 1) */}
        <NumberInput
          value={lots.toString()}
          onChange={value => setLots(Number(value))}
          label="Lots"
          width="100%"
          min={1}
          sx={{
            '& .MuiInputBase-input': {
              fontSize: '11px',
              '--Input-minHeight': '0',
            },
            '& .MuiInputLabel-root': {
              fontSize: '11px',
              '&.Mui-focused, &.MuiFormLabel-filled': {
                fontSize: '13px',
              },
            },
            '& .MuiInputBase-root': {
              borderRadius: '0px',
              height: 35,
            },
          }}
        />

        {/* Duration Input (min value 1) */}
        <NumberInput
          value={duration.toString()}
          onChange={value => setDuration(Number(value))}
          label="Duration (s)"
          min={1}
          width="100%"
          sx={{
            '& .MuiInputBase-input': {
              fontSize: '11px',
              '--Input-minHeight': '0',
            },
            '& .MuiInputLabel-root': {
              fontSize: '11px',
              '&.Mui-focused, &.MuiFormLabel-filled': {
                fontSize: '13px',
              },
            },
            '& .MuiInputBase-root': {
              borderRadius: '0px',
              height: 35,
            },
          }}
        />

        {/* Bid and Ask Buttons */}
        <Stack direction="row" spacing={2} justifyContent="center">
          <StyledButton key="bid" variant="contained" onClick={() => handleSubmit('Bid')} isBuy disabled={!isFormValid}>
            <Typography variant="h6" style={{ fontSize: 11, lineHeight: 0, marginBottom: 3 }} tabIndex={-1}>
              Bid
            </Typography>
          </StyledButton>

          <StyledButton key="ask" variant="contained" onClick={() => handleSubmit('Ask')} disabled={!isFormValid}>
            <Typography variant="h6" style={{ fontSize: 11, lineHeight: 0, marginBottom: 3 }} tabIndex={-1}>
              Ask
            </Typography>
          </StyledButton>
        </Stack>
      </Stack>
    </Box>
  );
};
