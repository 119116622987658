import { Paper, Stack, Tab, Tabs } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { AdvancedOCLTrading } from './AdvancedOCLTrading';
import { SimpleOCLTrading } from './SimpleOCLTrading';

type Tab = { label: string; value: string; Component: React.ReactNode };
const OCLTradingTabs: Tab[] = [
  { label: 'Advanced', value: 'advanced', Component: <AdvancedOCLTrading /> },
  { label: 'Simple', value: 'simple', Component: <SimpleOCLTrading /> },
];

export const OCLTrading = () => {
  const { activeTab: tabInURL } = useParams();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [selectedTab, setSelectedTab] = useState<Tab>(OCLTradingTabs[0]);

  const onTabChange = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      const tab = OCLTradingTabs.find(tab => tab.value === newValue);
      if (tab && tab.value !== tabInURL) {
        setSelectedTab(tab);
        setSearchParams({});
        navigate(`/ocl-trading/${tab.value}`);
      }
    },
    [tabInURL]
  );

  useEffect(() => {
    if (!tabInURL && selectedTab) {
      const params = searchParams.toString();
      navigate(`/ocl-trading/${selectedTab.value}${params ? `?${params}` : ''}`);
    } else if (!tabInURL && !selectedTab) {
      setSelectedTab(OCLTradingTabs[0]);
    } else if (tabInURL && !selectedTab) {
      const tab = OCLTradingTabs.find(tab => tab.value === tabInURL);
      if (tab) {
        setSelectedTab(tab);
      }
    } else if (tabInURL && selectedTab && tabInURL !== selectedTab.value) {
      const tab = OCLTradingTabs.find(tab => tab.value === tabInURL);
      if (tab) {
        setSelectedTab(tab);
      }
    }
  }, [tabInURL, selectedTab, searchParams]);

  return (
    <Stack display="flex" flexDirection="column" flex={1} gap={1} padding={2}>
      <Stack display="flex" flexDirection="row" gap={1}>
        <Paper style={{ flex: 1, maxWidth: 'calc(100vw - 106px)' }}>
          <Tabs value={selectedTab.value} onChange={onTabChange} variant="scrollable">
            {OCLTradingTabs.map(tab => (
              <Tab key={tab.value} label={tab.label} value={tab.value} style={{ fontSize: 11 }} />
            ))}
          </Tabs>
        </Paper>
      </Stack>

      <Paper style={{ display: 'flex', flex: 1 }}>{selectedTab.Component}</Paper>
    </Stack>
  );
};
