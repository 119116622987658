import { useAdminApi } from '@hooks/useAdminApi';
import { ContractLadderResponse, CreateLadderRequest } from '@protos/ladders';
import { useCallback } from 'react';

export const useLaddersApi = () => {
  const { apiClient } = useAdminApi();

  const createLadder = useCallback(
    async (payload: CreateLadderRequest) => {
      if (!apiClient) return;

      const { symbol, expiry_timestamp, orders, account_id } = payload;
      const ladderRequest: CreateLadderRequest = {
        symbol,
        expiry_timestamp,
        orders,
        account_id,
      };

      const ladders = await apiClient.createNewLadder(ladderRequest);
      return ladders;
    },
    [apiClient]
  );

  const fetchLaddersForContracts = useCallback(
    async (symbols: string[]): Promise<ContractLadderResponse[]> => {
      if (!apiClient) return [];

      try {
        const ladders = await apiClient.getLaddersForContracts(symbols);
        return ladders;
      } catch (error) {
        console.error('Error fetching ladders:', error);
        return [];
      }
    },
    [apiClient]
  );

  return { createLadder, fetchLaddersForContracts };
};
