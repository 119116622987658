import { BlotterWidget } from '@shared/components/BotterWidget';
import { useApi } from '@shared/hooks/useApi';
import { PRODUCT_COLUMNS, PRODUCTS_FILTERS } from '@shared/protos/options';
import { ColDef } from 'ag-grid-community';
import { NavigateFunction, useNavigate } from 'react-router-dom';

const getRowId = ({ data }): string => data?.id?.toString();

const defaultColDef = (navigate: NavigateFunction) => {
  return {
    sortable: true,
    filter: false,
    floatingFilter: false,
    resizable: true,
    width: 150,
    onCellClicked(event) {
      if (event.column.getColId() === 'symbol') {
        navigate(`/admin/products/${event.data.symbol}`);
      }
    },
    cellStyle(params) {
      if (params.column.getColId() === 'symbol') {
        return { cursor: 'pointer' };
      }
      return null;
    },
  } as ColDef;
};

const Products = () => {
  const { apiClient } = useApi();
  const dataLoader = apiClient?.productsLoader();
  const navigate = useNavigate();

  if (!dataLoader) return null;

  return (
    <BlotterWidget
      dataLoader={dataLoader}
      columnDefs={PRODUCT_COLUMNS}
      getRowId={getRowId}
      filters={PRODUCTS_FILTERS}
      defaultColDef={defaultColDef(navigate)}
      enableCellTextSelection
      autoSizeStrategy={{
        type: 'fitCellContents',
        colIds: ['full_name'],
      }}
    />
  );
};

export default Products;
