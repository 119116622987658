import { useURLSearchParams } from '@hooks/useURLSearchParams';
import { Box, Divider, IconButton, Paper, Stack, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { IconComponent } from '@shared/components/IconComponent';
import { Filter, FilterType } from '@shared/protos/filter';
import { toLocaleISOString } from '@utils/date';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CircularLoader } from './CircularLoader';
import { FilterComponent } from './FilterComponent';
import { Search } from './Search';

interface FilterPanelProps {
  filters?: Filter[];
  onSearch: (text: string) => void;
  disableSearch?: boolean;
  children?: React.ReactNode;
  title?: string;
  onRefreshClicked?: () => void;
  loading?: boolean;
}

const FilterContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1.5),
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: theme.spacing(1),
}));

export const FilterPanel = ({ filters, onSearch, disableSearch, title, onRefreshClicked, children, loading }: FilterPanelProps) => {
  const { urlParams, resetURLSearchParams, setURLSearchParam } = useURLSearchParams();

  const [isLoadingFilters, setIsLoadingFilters] = useState(true);

  const defaultFilterParams = useMemo(
    () =>
      filters?.reduce((acc, filter) => {
        const { name, defaultValue } = filter;
        if (defaultValue && typeof defaultValue !== 'object') acc[name] = defaultValue;
        else if (defaultValue && typeof defaultValue === 'object') {
          const { start, end } = defaultValue;
          acc['start'] = toLocaleISOString(start);
          if (end) acc['end'] = toLocaleISOString(end);
        }

        return acc;
      }, {}),
    [filters]
  );

  const isValidURLParams = useMemo(
    () =>
      urlParams
        ? filters?.every(filter => {
            if ([FilterType.AUTOCOMPLETE, FilterType.SELECT].includes(filter.type)) {
              const filterValue = urlParams[filter.name];
              if (filterValue && !filter.options?.includes(filterValue)) {
                return false;
              }
              return true;
            }
            return true;
          })
        : true,
    [urlParams, filters]
  );

  const [filterParams, setFilterParams] = useState<Record<string, any>>(defaultFilterParams || {});

  const onFilterChange = useCallback(
    (newFilter: Record<string, any>) => {
      setIsLoadingFilters(true);
      const newFilterParams = { ...filterParams, ...newFilter };
      setFilterParams(newFilterParams);
      resetURLSearchParams(newFilterParams);
      setIsLoadingFilters(false);
    },
    [filterParams, resetURLSearchParams]
  );

  const onSearchChange = useCallback(
    (val: string) => {
      setIsLoadingFilters(true);
      const newFilterParams = { ...filterParams, search: val };

      setFilterParams(newFilterParams);
      setURLSearchParam('search', val);

      onSearch(val);
      setIsLoadingFilters(false);
    },
    [filterParams, onSearch, setURLSearchParam]
  );

  useEffect(() => {
    if (!isValidURLParams) {
      resetURLSearchParams(defaultFilterParams || {});
    } else {
      const transformedFilterParams = { ...defaultFilterParams, ...urlParams };

      if (JSON.stringify(filterParams) !== JSON.stringify(transformedFilterParams)) {
        setFilterParams(transformedFilterParams);
      }
    }

    setIsLoadingFilters(false);
  }, [urlParams, isValidURLParams, defaultFilterParams, resetURLSearchParams, filterParams]);

  return (
    <FilterContainer square>
      <Box style={{ width: '100%', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', gap: '10px', marginBottom: '-3px' }}>
        <Stack direction="row" style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', alignItems: 'center' }}>
          {title && <Typography variant="h4">{title}</Typography>}
          {title && <Divider orientation="vertical" style={{ marginLeft: 5 }} />}
          {filters?.map(filter => (
            <FilterComponent key={filter.name} filter={filter} filterParams={filterParams} onFilterChange={onFilterChange} />
          ))}
          {children}
          {onRefreshClicked && (
            <>
              <Divider orientation="vertical" style={{ marginLeft: 5 }} />
              <Tooltip title="Refresh">
                <IconButton onClick={onRefreshClicked} size="small" sx={{ padding: 0 }}>
                  <IconComponent name="ri-refresh-line" />
                </IconButton>
              </Tooltip>
            </>
          )}
        </Stack>
        {!disableSearch && (
          <Box display={'flex'} alignItems={'center'} gap={1}>
            <Search
              onSearch={onSearchChange}
              value={filterParams.search || ''}
              maxWidth="170px"
              endAdornment={<CircularLoader loading={loading || isLoadingFilters} />}
            />
          </Box>
        )}
      </Box>
    </FilterContainer>
  );
};
