import { useApi } from '@hooks/useApi';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { IconButton, Tooltip } from '@mui/material';
import { ExecutionReport } from '@protos/trading';
import { config } from '@services/context';
import { ICellRendererParams } from 'ag-grid-community';
import { useCallback } from 'react';

const generateFileDownloadUrl = (path: string) => {
  const env = config.isUAT ? 'uat' : config.isDev ? 'dev' : undefined;
  return `https://app.${env ? env + '.' : ''}onyxhub.co/a1/executions-reports/${path}`;
};

export const TradingAdminABNReportsActionsCellRenderer = (props: ICellRendererParams<ExecutionReport>) => {
  const { apiClient } = useApi();

  const requestFileDownload = useCallback(async () => {
    if (!props.data?.file_url || !apiClient) return;

    try {
      const response = await fetch(generateFileDownloadUrl(props.data?.file_url), {
        method: 'GET',
        headers: {
          authorization: `Bearer ${apiClient.getToken()}`,
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      window.open(url, '_blank');

      setTimeout(() => {
        window.URL.revokeObjectURL(url);
      }, 1000);
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  }, [props.data?.file_url, apiClient]);

  return (
    <Tooltip title="Download ABN Report">
      <IconButton onClick={requestFileDownload} style={{ padding: 0, marginBottom: 3 }}>
        <FileDownloadIcon style={{ fontSize: '16px' }} />
      </IconButton>
    </Tooltip>
  );
};
