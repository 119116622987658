import { Autocomplete, Box, Popper, styled, TextField } from '@mui/material';
import { LadderRequestSettings } from '@protos/ladders';
import { Product } from '@protos/product';
import { useUserProductsAndTenorsContext } from '@shared/contexts/UserProductsAndTenorsProvider';
import { useCallback, useEffect, useState } from 'react';
import NumberInput from '../NumberInput';

const SmallAutocomplete = styled(Autocomplete)({
  width: '125px',
  borderRadius: 0,
  '& .MuiAutocomplete-inputRoot': {
    padding: 0,
    fontSize: 11,
    height: 25,
    borderRadius: 0,
  },
  '& .MuiSvgIcon-root': {
    fontSize: 20,
    padding: 0,
  },
});

const StyledPopper = styled(Popper)({
  '& .MuiAutocomplete-listbox': {
    fontSize: 11,
  },
});

interface SpreadSettingsProps {
  spreadSettings: LadderRequestSettings;
  setSpreadSettings: (settings: LadderRequestSettings) => void;
}

export const SpreadSettings = ({ spreadSettings, setSpreadSettings }: SpreadSettingsProps) => {
  const { userProducts } = useUserProductsAndTenorsContext();

  const allSpreadProducts = userProducts.filter(product => product.calendar_type === 'spread');
  const [selectedValue, setSelectedValue] = useState<Product>(
    allSpreadProducts.find(p => p.symbol === spreadSettings.symbol) || allSpreadProducts[0]
  );

  const onProductChange = useCallback(
    (newProductValue: string) => {
      const product = allSpreadProducts.find(p => p.symbol === newProductValue);
      if (product) {
        setSelectedValue(product);
        setSpreadSettings({ ...spreadSettings, symbol: product.symbol });
      }
    },
    [allSpreadProducts, setSpreadSettings, spreadSettings]
  );

  const onValidityChange = useCallback(
    (value: number) => {
      setSpreadSettings({ ...spreadSettings, validity: value });
    },
    [setSpreadSettings, spreadSettings]
  );

  const onSizeChange = useCallback(
    (value: number) => {
      setSpreadSettings({ ...spreadSettings, size: value });
    },
    [setSpreadSettings, spreadSettings]
  );

  useEffect(() => {
    if (spreadSettings?.symbol !== selectedValue?.symbol) {
      setSpreadSettings({ ...spreadSettings, symbol: selectedValue?.symbol });
    }
  }, [selectedValue, spreadSettings]);

  useEffect(() => {
    if (!allSpreadProducts.length) return;

    if (!selectedValue) {
      setSelectedValue(allSpreadProducts.find(p => p.symbol === spreadSettings.symbol) || allSpreadProducts[0]);
    }
  }, [selectedValue, spreadSettings, allSpreadProducts]);

  if (!selectedValue) return null;

  return (
    <Box display="flex" flex={1} justifyContent="end" flexWrap="wrap">
      <NumberInput
        value={spreadSettings.validity.toString()}
        onChange={onValidityChange}
        label="Validity (s)"
        min={1}
        sx={{
          '& .MuiInputBase-input': {
            fontSize: '11px',
            '--Input-minHeight': '0',
          },
          '& .MuiInputBase-root': {
            borderRadius: '0px',
            height: 25,
          },
        }}
      />
      <NumberInput
        value={spreadSettings.size.toString()}
        onChange={onSizeChange}
        label="Lots"
        min={1}
        sx={{
          '& .MuiInputBase-input': {
            fontSize: '11px',
            '--Input-minHeight': '0',
          },
          '& .MuiInputBase-root': {
            borderRadius: '0px',
            height: 25,
          },
        }}
      />

      <SmallAutocomplete
        size="small"
        disableClearable
        options={allSpreadProducts}
        getOptionLabel={(option: unknown) => (option as Product).symbol.toUpperCase()}
        value={selectedValue}
        onChange={(_, newValue) => newValue && onProductChange((newValue as Product).symbol)}
        autoHighlight
        PopperComponent={props => <StyledPopper {...props} />}
        renderInput={params => <TextField {...params} variant="outlined" tabIndex={-1} />}
        isOptionEqualToValue={(option, value) => (option as Product).symbol === (value as Product).symbol}
        sx={{
          '& .MuiAutocomplete-input': { fontSize: 11, paddingLeft: 1.5 },
        }}
      />
    </Box>
  );
};
