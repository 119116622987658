import { formatDatetimeYYYYMMDD } from '@shared/utils/date';
import { ColDef } from 'ag-grid-community';
import { Filter, FilterType } from './filter';
import { PRODUCT_GROUP_OPTIONS } from './options';

export type SettlementProductResponse = {
  product_symbol: string;
  prices: SettlementPriceWithDate[];
};

export type SettlementPriceWithDate = {
  date: string;
  price: string;
  source: string;
  code: string;
  last_modified: string;
};

export type SettlementProductParams = {
  date?: string;
  search?: string;
  product_group?: string;
};

export type SettlementProduct = SettlementPriceWithDate & { product_symbol: string };

export const SETTLEMENT_PRODUCT_COLUMNS: ColDef<SettlementProduct>[] = [
  {
    colId: 'symbol',
    field: 'product_symbol',
    headerName: 'Product Symbol',
  },
  {
    field: 'source',
    headerName: 'Source',
  },
  {
    field: 'code',
    headerName: 'Code',
  },
  {
    field: 'price',
    headerName: 'Price',
  },
  {
    field: 'date',
    headerName: 'Date',
  },
  {
    field: 'last_modified',
    headerName: 'Last modified',
    valueFormatter: ({ value }) => (value ? formatDatetimeYYYYMMDD(value) : ''),
  },
];
export const SETTLEMENT_PRODUCT_FILTERS: Filter[] = [
  {
    type: FilterType.DATE,
    name: 'date',
    label: 'Date',
  },
  {
    type: FilterType.AUTOCOMPLETE,
    name: 'product_group',
    label: 'Product Group',
    options: PRODUCT_GROUP_OPTIONS.sort((a, b) => a.localeCompare(b)),
  },
];

export const ADMIN_SETTLEMENTS_FOR_PRODUCT_COLUMNS: ColDef<SettlementPriceWithDate>[] = [
  {
    field: 'date',
    headerName: 'Date',
  },
  {
    field: 'source',
    headerName: 'Source',
  },
  {
    field: 'code',
    headerName: 'Code',
  },

  {
    field: 'price',
    headerName: 'Price',
  },
  {
    field: 'last_modified',
    headerName: 'Last modified',
    valueFormatter: ({ value }) => (value ? formatDatetimeYYYYMMDD(value) : ''),
  },
];
