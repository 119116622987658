import { useApi } from '@hooks/useApi';
import { Box } from '@mui/material';
import { SearchSymbolResponse } from '@protos/charts';
import theme from '@shared/themes/darkTheme';
import { BarPrice, ColorType, IChartApi, Time, createChart } from 'lightweight-charts';
import { useEffect, useRef, useState } from 'react';

import { useDashboardContext } from '@shared/contexts/DashboardContext';
import { WidgetProps } from '../../types';
import Widget, { Content, Header } from '../Widget';
import Settings from '../settings/Settings';
import { SeasonalChartContent } from './components/SeasonalChartContent';
import { SeasonalChartToolbar } from './components/SeasonalChartToolbar';
import { DEFAULT_CHART_SYMBOL, SeasonalChartPayload, chartLineColors, chartMonthNames } from './seasonalChartConsts';
import { useSeasonalCharts } from './useSeasonalCharts';

type SeasonalChartProps = WidgetProps & {
  payload?: SeasonalChartPayload;
};

export const SeasonalChart = ({
  payload = { chartSymbol: DEFAULT_CHART_SYMBOL, chartFormula: '', isFormulasEnabled: false },
  ...otherProps
}: SeasonalChartProps) => {
  const { apiClient } = useApi();
  const { editWidgetPayloadById } = useDashboardContext();
  const { id, title } = otherProps as { id: string; title: string };
  const { chartSymbol, chartFormula, isFormulasEnabled } = payload;

  const chartContainerRef = useRef<HTMLDivElement>();
  const widgetContainerRef = useRef<HTMLDivElement>(null);
  const chartRef = useRef<IChartApi>();
  const lineSeriesRefs = useRef<any>([]);

  const [selectedSymbol, setSelectedSymbol] = useState<SearchSymbolResponse>(chartSymbol);

  const [allLineSeries, setAllLineSeries] = useState<Record<string, { value: string; label: string; isSelected: boolean }>>({});
  const [chartLineColorMap, setChartLineColorMap] = useState<Record<string, string>>({});

  const {
    selectedFormula,
    chartData,
    isLoading,
    isUsingFormulas,
    hasError,
    isDataAvailable,
    isUsingRollingInFormula,
    onFormulaQuery,
    onQuerySymbol,
    onFormulasEnabledChange,
  } = useSeasonalCharts(chartFormula, isFormulasEnabled);

  useEffect(() => {
    if (!chartContainerRef.current || !widgetContainerRef.current || (chartData && !Object.keys(chartData).length) || !apiClient) return;

    const chart = createChart(chartContainerRef.current, {
      width: widgetContainerRef.current.clientWidth,
      height: widgetContainerRef.current.clientHeight,
      layout: { background: { type: ColorType.Solid, color: theme.palette.background.darker }, textColor: 'white' },
      grid: { horzLines: { visible: false }, vertLines: { visible: false } },
      timeScale: {
        timeVisible: false,
        secondsVisible: false,
        borderColor: theme.palette.background.lighter,
        fixLeftEdge: true,
        fixRightEdge: true,
        uniformDistribution: false,
        tickMarkFormatter: (time: Time) => {
          const date = new Date(+time * 1000);

          const month = date.getMonth();
          const year = date.getFullYear();

          if ([0].includes(month)) {
            return `${chartMonthNames[month].toLocaleUpperCase()}${String(year).slice(-2)}`;
          } else {
            return chartMonthNames[month].toLocaleUpperCase();
          }
        },
      },
      leftPriceScale: { visible: true },
      rightPriceScale: { visible: false },
      localization: {
        priceFormatter: (price: BarPrice) => (price || price === 0 ? Number(price).toFixed(2) : undefined),
      },
    });

    let lastBarDataTime: number | undefined;
    lineSeriesRefs.current = Object.keys(chartData).map((key, index) => {
      const lineSeries = chart.addLineSeries({ priceFormat: { type: 'price', precision: 2, minMove: 1 } });
      const lineData = chartData[key].map(({ time, close }) => ({ time, value: close }));

      const lastIndexOfDash = key.lastIndexOf('-');
      const lineTitle =
        (!isUsingFormulas || (isUsingFormulas && isUsingRollingInFormula)) && lastIndexOfDash !== -1
          ? key.slice(lastIndexOfDash + 1, key.length)
          : key;
      const formattedLineTitle = lineTitle.toLocaleUpperCase().replace(/[{}]/g, '');

      if (lineData.length === 0) return lineSeries;

      setAllLineSeries(prev => ({
        ...prev,
        [lineTitle]: { value: lineTitle, label: formattedLineTitle, isSelected: true },
      }));

      lineSeries.applyOptions({
        color: chartLineColors[index],
        lineWidth: 2,
        title: formattedLineTitle,
        priceLineVisible: false,
      });

      const latestLineDataTime = lineData[lineData.length - 1].time as number;
      if (!lastBarDataTime || (lastBarDataTime && lastBarDataTime <= latestLineDataTime)) lastBarDataTime = latestLineDataTime;

      setChartLineColorMap(prev => ({ ...prev, [lineTitle]: chartLineColors[index] }));
      lineSeries.setData(lineData);
      return lineSeries;
    });

    const resizeObserver = new ResizeObserver(entries => {
      entries.forEach(entry => {
        if (entry.contentBoxSize) {
          const contentBoxSize = Array.isArray(entry.contentBoxSize) ? entry.contentBoxSize[0] : entry.contentBoxSize;
          const width = contentBoxSize.inlineSize;
          const height = contentBoxSize.blockSize;
          chart.resize(width, (height || 100) - (height >= 100 ? 100 : 0));
        } else {
          const width = entry.contentRect.width;
          const height = entry.contentRect.height;
          chart.resize(width, (height || 100) - (height >= 100 ? 100 : 0));
        }
      });
    });

    resizeObserver.observe(widgetContainerRef.current);
    chartRef.current = chart;

    if (lastBarDataTime) {
      const currentYear = new Date().getFullYear();
      const visibleRangeYear = new Date(lastBarDataTime * 1000).getFullYear();

      if (currentYear >= visibleRangeYear) {
        const to = lastBarDataTime * 1000;
        const from = new Date(to);
        from.setFullYear(from.getFullYear() - 1);
        chart.timeScale().setVisibleRange({ from: (from.getTime() / 1000) as Time, to: (to / 1000) as Time });
      } else {
        const from = new Date();
        const to = new Date(from);
        from.setDate(1);
        from.setMonth(0);
        to.setFullYear(to.getFullYear() + 1);
        chart.timeScale().setVisibleRange({ from: (from.getTime() / 1000) as Time, to: (to.getTime() / 1000) as Time });
      }
    }

    return () => {
      resizeObserver.disconnect();
      setAllLineSeries({});
      chart.remove();
    };
  }, [chartData, apiClient, isUsingFormulas]);

  useEffect(() => {
    if (!chartData) return;

    Object.keys(chartData).forEach((key, index) => {
      const currLineSeries = lineSeriesRefs.current[index];
      const lastIndexOfDash = key.lastIndexOf('-');
      const lineSeriesKey =
        (!isUsingFormulas || (isUsingFormulas && isUsingRollingInFormula)) && lastIndexOfDash !== -1
          ? key.slice(lastIndexOfDash + 1, key.length)
          : key;

      if (currLineSeries) {
        const isVisible = allLineSeries[lineSeriesKey]?.isSelected;
        currLineSeries.applyOptions({ visible: isVisible });
      }
    });
  }, [allLineSeries, chartData]);

  useEffect(() => {
    if (!isUsingFormulas) {
      onQuerySymbol(selectedSymbol.symbol);
    }

    editWidgetPayloadById(id, { chartSymbol: selectedSymbol, isFormulasEnabled: isUsingFormulas, chartFormula: selectedFormula });
  }, [isUsingFormulas, selectedFormula, selectedSymbol, id, onQuerySymbol]);

  if (!apiClient) return null;

  return (
    <Widget {...otherProps} ref={widgetContainerRef}>
      <Header title={title}>
        <Settings id={`seasonal-chart-settings-${id}`} title={title} widgetId={id} />
      </Header>
      <Content>
        <Box display="flex" flexDirection="column" flex={1} paddingTop={1}>
          <SeasonalChartToolbar
            chartRef={chartRef}
            chartContainerRef={chartContainerRef}
            selectedFormula={selectedFormula}
            onQuerySeasonalFormula={onFormulaQuery}
            useFormulas={isUsingFormulas}
            onFormulasEnabledChange={onFormulasEnabledChange}
            searchSelectedSymbol={selectedSymbol}
            onSearchSelectedSymbolChange={(newSymbol: SearchSymbolResponse) => setSelectedSymbol(newSymbol)}
          />
          <SeasonalChartContent
            chartContainerRef={chartContainerRef}
            chartLineColorMap={chartLineColorMap}
            allLineSeries={allLineSeries}
            isLoading={isLoading}
            hasError={hasError}
            hasNoData={!isDataAvailable}
            setAllLineSeries={setAllLineSeries}
          />
        </Box>
      </Content>
    </Widget>
  );
};
